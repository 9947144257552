
<template>
<v-row>
    <v-dialog v-model="dialogDelete" max-width="380px"  transition="dialog-top-transition">
      <v-card class="pa-7">
        <v-card-title class="d-flex justify-center align-center">
          <v-row  dense>
            <v-col class="py-0 mb-1" cols="12">
                <span class="icon-background-alert">
                  <v-icon dense color="main_red" size="30px" class="my-0">mdi-alert-outline</v-icon>
                </span>
            </v-col>
            <v-col cols="12">
              <span>{{ $t('deleteQuestion') }}</span>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="text-center">{{ $t('questionTextForDelete') }}</v-card-text>
        <v-card-actions class="mb-2">
          <v-row dense>
            <v-col cols="12">
              <v-btn block depressed color="main_red" class="text-color-white" @click="deleteSurveyConfirmation">{{ $t('deleteField') }}</v-btn>
            </v-col>
            <v-col cols="12">
              <v-btn outlined depressed color="main_black" block @click="closeDeleteDialog">{{ $t("cancel") }}</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogEmail" max-width="380px"  transition="dialog-top-transition">
      <v-card class="pa-7">
        <v-card-title class="d-flex justify-center align-center">
          <v-row  dense>
            <v-col class="py-0 mb-1" cols="12">
                  <span class="icon-background-success">
                    <v-icon dense color="green" size="30px" class="my-0">mdi-email</v-icon>
                  </span>
            </v-col>
            <v-col cols="12">
              <span>{{ $t('deleteQuestion') }}</span>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="text-center">{{ $t('questionTextForDelete') }}</v-card-text>
        <v-card-actions class="mb-2">
          <v-row dense>
            <v-col cols="12">
              <v-btn block depressed color="green" class="text-color-white" @click="sendEmailConfirmation">{{ $t('send-download-link') }}</v-btn>
            </v-col>
            <v-col cols="12">
              <v-btn outlined depressed color="main_black" block @click="closeEmailDialog">{{ $t("cancel") }}</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  <v-col cols="12">
    <v-data-table
        v-if="billingsLinksList"
        :headers="headers"
        :search="appBarSearch"
        :no-data-text="$t('no-data-available')"
        :loading-text="$t('loading')"
        :headers-length="headers.length"
        :items="billingsLinksList"
        :footer-props="footerProps"
        :items-per-page="-1"
        sort-by="id"
        item-key="id"
        fixed-header
        dense
        :height="pageHeight(150)"
        class=" pt-5 px-5 mt-2  medical-staff-data-table elevation-3"
    >
      <template v-slot:item="{ item }">
        <tr>
          <td class="text-left">
              <span class=" font-weight-medium font-size13 d-flex align-items-center">
                <v-img style="max-height: 18px; max-width:18px; margin-right:4px;"  :src="profileSettingsBlack"></v-img>
                {{ item.linkCreatedByFullname }}
              </span>
          </td>
          <td class="text-left">
            <v-icon size="15" color="primary">mdi-email</v-icon>
              <span class=" font-weight-medium font-size13">{{ item.sendTo }}</span>
          </td>
          <td class="text-left font-weight-medium font-size13" >
            <v-icon small color="primary">mdi-calendar</v-icon>
            {{ formatBillingDate(item.billingMonth, item.billingYear) }}
          </td>
          <td class="text-left">
            <v-icon small color="primary">mdi-calendar-clock</v-icon>
            <span class=" font-weight-medium font-size13">{{ formatDate(item.expiresAt) }}</span>
          </td>
          <td class="text-left">
            <span class=" font-weight-medium font-size13">{{ checkStatus(item.status) }}</span>
          </td>
          <td class="text-left">
            <v-icon class=" font-weight-medium pl-4 font-size20"  :class="item.isEmailSent ? 'color-green' :'color-primary'">{{ checkIsEmailSent(item.isEmailSent) }}</v-icon>
          </td>
          <td class="text-left">
            <v-icon class=" font-weight-medium pl-7 font-size20" :class="item.isEmailDownloaded ? 'color-green' : 'color-primary'">{{ checkIsEmailDownloaded(item.isEmailDownloaded) }}</v-icon>
          </td>
          <td class="text-right">
            <v-menu
                left
                bottom
                class="py-0"
                offset-y
                transition="slide-x-transition"
            >
              <template v-slot:activator="{on, attrs}">
                <v-btn
                    icon
                    color="black"
                    v-bind="attrs"
                    v-on="on"
                >
                  <span class="mdi mdi-18px mdi-dots-horizontal "></span>
                </v-btn>
              </template >

              <v-list class="px-0" dense style="width:250px;">
                <v-list-item @click="syncronizeDrawers(item)" class="py-0 my-0" link dense>
                  <v-list-item-icon class="mx-1 ">
                    <v-icon class="icon-img mdi-18px" color="black">mdi-pencil</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content class="font-bold font-size14">{{$t("edit")}}</v-list-item-content>
                </v-list-item>
                <v-list-item @click.stop="sendEmail(item)" link dense >
                  <v-list-item-icon class="mx-1 ">
                    <v-icon class="icon-img mdi-18px" color="black">mdi-email</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content class="font-bold font-size14" >{{ $t('send-download-link') }}</v-list-item-content>
                </v-list-item>
                <v-divider/>
                <v-list-item @click.stop="deleteSurveyById(item)">
                  <v-list-item-icon  class="mx-1 ">
                    <v-icon color="red">mdi-trash-can-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content class="font-bold font-size14" >{{ $t('delete') }}</v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-col>
</v-row>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import BillingsRepository from '@/data/repositories/v1/BillingsRepository';
import { profileSettingsBlack } from '@/assets';
import { showSuccessAlert } from '@/utils/utilities';
export default {
  name: 'BillingReports.vue',
  data () {
    return {
      dialogDelete: false,
      dialogEmail: false,
      selectedItemToEmail: null,
      selectedItemId: null,
      footerProps: { 'items-per-page-options': [-1, 5, 10, 50, 100] },
    };
  },
  computed: {
    profileSettingsBlack () {
      return profileSettingsBlack;
    },
    ...mapState({
      billingsLinksList: (state) => state.billings.billingsLinksList,
      authenticated: state => state.authentication.userData,
    }),
    ...mapGetters({
      pageHeight: 'pageHeight',
      appBarBillingDate: 'filterbar/getBillingDate',
      appBarSearch: 'filterbar/getSearch',
    }),
    headers () {
      const headers = [
        { text: this.$t('created-by'), value: 'linkCreatedByFullname',	class: 'table-header', align: 'start pl-4' },
        { text: this.$t('send-to'), value: 'sendTo',	class: 'table-header', align: 'start pl-4' },
        { text: this.$t('billing-month'), value: 'sendTo',	class: 'table-header', align: 'start pl-4' },
        { text: this.$t('expires-at'), value: 'expiresAt',	class: 'table-header', align: 'start pl-4' },
        { text: this.$t('status'), value: 'sendTo',	class: 'table-header', align: 'start pl-4' },
        { text: this.$t('email-sent'), value: 'sendTo',	class: 'table-header', align: 'start pl-4' },
        { text: this.$t('email-downloaded'), value: 'sendTo',	class: 'table-header', align: 'start pl-4' },
        { text: this.$t('actions'), value: '',	class: 'table-header', align: 'end' },
      ];
      return headers;
    },
  },
  created () {
    this.getBillingOrganizationLinks();
    this.getPartners();
  },
  methods: {
    formatBillingDate (billingMonth, billingYear) {
      const date = new Date(billingYear, billingMonth - 1, 1); // Create a Date object with the first day of the month
      return date.toLocaleDateString('en-US', {
        month: 'short',
        year: 'numeric',
      });
    },
    async getPartners () {
      const organizationId = this.roleName === 'SYSTEM_ADMIN' ? -1 : this.authenticated.organizationId;
      const body = {
        organizationId: organizationId,
      };

      await this.$store.dispatch('users/getPartners', body);
    },
    checkIsEmailDownloaded (download) {
      switch (download) {
        case false :
          return 'mdi-close-circle';
        case true :
          return 'mdi-check-circle';
      }
    },
    checkIsEmailSent (email) {
      switch (email) {
        case false :
          return 'mdi-close-circle';
        case true :
          return 'mdi-check-circle';
      }
    },
    sendEmailConfirmation () {
      const billingCodeLinkId = this.selectedItemToEmail.id;
      this.$store.dispatch('billings/sendBillingLink', billingCodeLinkId).then(res => {
       // console.log(res);
        showSuccessAlert('Success! The billing link has been sent to the recipient\'s email.');
       this.getBillingOrganizationLinks();
        this.dialogEmail = false;
      });
    },
    formatDate (dateString) {
      const date = new Date(dateString); // Convert the string to a Date object
      return date.toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      });
    },
    // 1-Active, 2-Expired, 3-Used
    checkStatus (status) {
      switch (status) {
        case 1 :
          return this.$t('active');
        case 2:
          return this.$t('expired');
        case 3:
          return '';
        default:
          return 'Expired';
      }
    },
    async deleteSurveyConfirmation () {
      await BillingsRepository.deleteBillingLink(this.selectedItemId);
      await this.getBillingOrganizationLinks();
      this.dialogDelete = false;
    },
    async deleteSurveyById (item) {
      this.selectedItemId = item.id;
      this.dialogDelete = true;
    },
    async sendEmail (item) {
      this.selectedItemToEmail = item;
      this.dialogEmail = true;
    },
    syncronizeDrawers (item) {
      this.$store.commit('SET_UPDATE_DETAILS_DRAWER', {
        updateDrawer: true,
        page2: 'links',
        item: item,
      });
    },
    closeDeleteDialog () {
      this.dialogDelete = false;
      // this.surveyId = '';
    },
    closeEmailDialog () {
      this.dialogEmail = false;
      // this.surveyId = '';
    },
    async getBillingOrganizationLinks () {
      await this.$store.dispatch('billings/getBillingLinksForOrganization');
    },
  },

};
</script>

<style>

</style>
